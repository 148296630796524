'use strict'

import Splide from '@splidejs/splide'

export const sliders = (() => {

    const carousels = []

    const initSliders = () => {
        const _sliderContainers = document.querySelectorAll('.splide')

        _sliderContainers.forEach(sliderContainer => {
            const newSlider = new Splide(sliderContainer)

            carousels.push(newSlider)

            if(sliderContainer.id == 'intro_video_carousel') {
                window.OverMorgen.introVideoCarouse = newSlider
            }
        })

        carousels.forEach(carousel => carousel.mount())
    }

    const init = () => {
        initSliders()

        window.Adamant.initSliders = initSliders
    }

    return {
        init
    }
})()