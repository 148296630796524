import {header} from './modules/header'
import {videos} from './modules/videos'
import {popups} from './modules/popups'
import {buttons} from './modules/buttons'
import {forms} from './modules/forms'
import {sliders} from './modules/sliders'
import {children} from './modules/children'

window.Adamant = {}

const afterLoad = () => {
    header.init()
    videos.init()
    popups.init()
    buttons.init()
    forms.init()
    sliders.init()
    children.init()
}
  
document.addEventListener('DOMContentLoaded', afterLoad)