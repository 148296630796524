export const buttons = (() => {
    const initScrollDownButtons = () => {
        const _containers = document.querySelectorAll('.page__scrolldown__button')

        _containers.forEach(_container => {
            const _button = _container.querySelector('button')

            if(!_button) return

            _button.onclick = () => {
                const _section = _button.closest('section')

                if(!_section) return

                const _nextSection = _section.nextElementSibling

                if(!_nextSection) return

                _nextSection.scrollIntoView({
                    behavior: 'smooth'
                })

                _button.classList.add('extinguished')
            }
        })
    }

    const init = () => {
        initScrollDownButtons()
    }

    return {
        init
    }
}) ()