'use strict'

export const header = (() => {
    const headerElement = document.querySelector('body > header')

    const changeHeader = () => {
        if(window.scrollY > headerElement.offsetHeight) {
            
            if(!headerElement.classList.contains('header-hidden')) {
                headerElement.classList.add('header-hidden')
            }
            
            if(window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2) {
                if(!headerElement.classList.contains('header-fixed')) {
                    headerElement.classList.add('header-fixed')
                }
            } else {
                if(headerElement.classList.contains('header-fixed')) {
                    headerElement.classList.remove('header-fixed')
                }
            }

        } else {
            headerElement.classList.remove('header-fixed')
            headerElement.classList.remove('header-hidden')
        }
    }

    const init = () => {
        if(!headerElement) return

        document.onscroll = () => {
            changeHeader()
        }

        changeHeader()
    }

    return {
        init
    }
})()