'use strict'

export const videos = (() => {
    const lazyLoad = video => {
        const _src = video.dataset.src

        if(!_src) return

        video.src = _src
        delete video.dataset.src
    }

    const fetchVideoContent = (url, callackFunction) => {
        const _xhr = new XMLHttpRequest()

        _xhr.open('get', url)
        _xhr.responseType = 'arraybuffer'
        
        _xhr.onload = () => {
            callackFunction(_xhr.response)
        }

        _xhr.send()
    }

    const initStream = (video, mimeCodec) => {
        const _mediaSource = new MediaSource()

        video.src = URL.createObjectURL(_mediaSource)

        _mediaSource.addEventListener('sourceopen', () => {
            if(_mediaSource.readyState != 'open') return false

            const _src = video.dataset.src

            if(!_src) return

            delete video.dataset.src

            // console.log(_src);

            const _sourceBuffer = _mediaSource.addSourceBuffer(mimeCodec)
            // console.log(_sourceBuffer, _mediaSource.readyState);

            fetchVideoContent(_src, buf => {
                _sourceBuffer.addEventListener('updateend', () => {
                    // console.log(_sourceBuffer, _mediaSource.readyState);
                    
                    if(_sourceBuffer.updating && _mediaSource.readyState === 'open') {
                        _mediaSource.endOfStream()
                    }
                    video.play()
                })

                _sourceBuffer.appendBuffer(buf)
            })
        })
    }

    const initVideo = video => {
        const _mimeCodec = video.dataset.type

        if('MediaSource' in window && MediaSource.isTypeSupported(_mimeCodec)) {
            initStream(video, _mimeCodec)
        } else {
            lazyLoad(video)
        }
    }

    const initVideos = () => {
        const _videos = document.querySelectorAll('video[data-src]')

        _videos.forEach(video => initVideo(video))
    }

    const init = () => {
        initVideos()
    }

    return {
        init
    }
}) ()