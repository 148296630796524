'use strict'

export const popups = (() => {
    const moveToEnd = () => {
        const _modals = document.querySelectorAll('.modal')

        _modals.forEach(modal => {
            document.body.appendChild(modal)
        })
    }

    const clickButton = (event) => {
        const _button = event.target

        if (!_button) return
        
        const _popupId = _button.dataset.popup

        if (!_popupId) return
        
        const _popup = document.getElementById(_popupId)

        if(!_popup) return
        
        _popup.classList.add('show')
    }

    const closePopup = (event) => {
        const _button = event.target

        if (!_button) return
        
        const _popup = _button.closest('.modal')

        if (!_popup) return
        
        _popup.classList.remove('show')
    }

    const initButtons = () => {
        const _buttons = document.querySelectorAll('[data-popup]')
        const _closeButtons = document.querySelectorAll('[data-popup-close]')

        _buttons.forEach(button => {
            button.addEventListener('click', clickButton)
        })

        _closeButtons.forEach(button => {
            button.addEventListener('click', closePopup)
        })
    }

    const init = () => {
        moveToEnd()
        initButtons()
    }

    return {
        init
    }
})()