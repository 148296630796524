'use strict'

export const forms = (() => {
    const initValidations = () => {
        const _forms = document.querySelectorAll('form[novalidate]')

        _forms.forEach(form => {
            const _elements = Array.from(form.elements)
            const _submitButton = form.querySelector('[type="submit"]')

            if(!_submitButton) return

            _elements.forEach(_element => {

                ['input', 'change'].forEach(event => {
                    _element.addEventListener(event, () => {
                        if(_element.checkValidity()) {
                            _element.classList.add('is-valid')

                            if(_element.classList.contains('is-invalid')) {
                                _element.classList.remove('is-invalid')
                            }
                        } else {
                            _element.classList.add('is-invalid')

                            if(_element.classList.contains('is-valid')) {
                                _element.classList.remove('is-valid')
                            }
                        }

                        if(_elements.every(_element => _element.checkValidity())) {
                            _submitButton.disabled = null
                        } else {
                            _submitButton.disabled = "disabled"
                        }
                    })
                })
            })
        })
    }

    const initForms = () => {
        initValidations()
    }

    const init = () => {
        initForms()
        
        window.Adamant.initForms = initForms
    }

    return {
        init,
    }
}) ()