'use sttict'

export const children = (() => {
    const makeChildrenHeight = () => {
        const _blocks = document.querySelectorAll('[data-children-height]')

        _blocks.forEach(block => {
            const _children = block.children
            const _classToEvaluate = block.dataset.childrenHeight

            let _maxHeight = 0

            Array.from(_children).forEach(child => {
                const _childDiv = child.querySelector(_classToEvaluate)

                if(!_childDiv) return

                const _childDivHeight = _childDiv.clientHeight

                if(_childDivHeight > _maxHeight) {
                    _maxHeight = _childDivHeight
                }
            })

            Array.from(_children).forEach(child => {
                const _childDiv = child.querySelector(_classToEvaluate)

                if(!_childDiv) return

                _childDiv.style.height = _maxHeight + 'px'
            })

        })
    }

    const init = () => {
        setTimeout(() => {
            makeChildrenHeight()            
        }, 500)

        window.onresize = makeChildrenHeight
    }

    return {
        init
    }
}) ()